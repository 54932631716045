.screener-responses {
  border-left: var(--border);
  border-right: var(--border);
  margin: 0 1rem 1rem 0; }
  .screener-responses .row-head {
    padding: 0 1rem;
    margin-left: 0;
    margin-right: 0;
    width: inherit;
    border-top: var(--border);
    border-bottom: var(--border);
    background-color: var(--gray-300);
    align-items: baseline;
    justify-content: space-between; }
    .screener-responses .row-head label {
      font-weight: bolder;
      margin: 0.2rem 0; }
  .screener-responses .react-bootstrap-table {
    margin: 0.5rem 0;
    font-size: 90%; }
  .screener-responses .response-table {
    margin: auto;
    width: inherit;
    border: var(--border); }
  .screener-responses .header {
    background-color: var(--gray-200); }
  .screener-responses th.right-border {
    border-right: 1px solid var(--gray-500); }
  .screener-responses .text-truncate {
    max-width: 50rem; }
    .screener-responses .text-truncate .image-option {
      max-width: 6rem;
      max-height: 2rem;
      margin-bottom: 0.2rem; }
  .screener-responses .row.react-bootstrap-table-pagination {
    padding-top: 0;
    height: 3rem; }
    .screener-responses .row.react-bootstrap-table-pagination .page-item {
      border: none; }
  .screener-responses .response-stats {
    width: 6rem;
    text-align: right; }
