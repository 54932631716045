.link {
  color: var(--link-color);
  cursor: pointer; }

.clickable {
  cursor: pointer; }

.modal-header {
  background-color: var(--gray-200); }
  .modal-header button.close {
    border: none;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5; }

.modal-content .row > * {
  flex-shrink: inherit; }

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 314px;
  display: inline-block; }

.inline-block {
  display: inline-block; }
