.projects .header-row {
  width: 30%;
  padding-top: 0.5rem;
  display: flex;
  align-items: center; }

.projects .card-view {
  width: 100%;
  margin: 1rem 0 0 0.5rem; }
  .projects .card-view .card {
    display: inline-block;
    width: 17rem;
    height: 12rem;
    border: 1px solid var(--blue-3);
    border-radius: unset;
    margin: 0 1rem 1rem 0;
    padding: 1rem 0.5rem;
    font-size: 90%;
    cursor: pointer; }
    .projects .card-view .card .card-heading {
      display: flex;
      justify-content: space-between; }
      .projects .card-view .card .card-heading .label {
        width: 70%; }
      .projects .card-view .card .card-heading .status {
        font-weight: 600;
        color: var(--gray-700); }
    .projects .card-view .card .ir-percent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.5rem; }
      .projects .card-view .card .ir-percent > span {
        color: var(--gray-700); }
    .projects .card-view .card .stats-detail-number {
      float: right;
      margin-right: 5rem;
      font-size: 1rem;
      font-weight: 600; }

.projects .list-view {
  width: 90%;
  margin: 1rem 0 0 0; }

.projects .selected {
  border: 1px solid var(--blue-3);
  padding: 0.5rem; }

.projects .unselected {
  border: var(--transparent-border);
  padding: 0.5rem; }

.projects .text-ellipsis {
  width: 10rem; }

.projects .project-summary .summary {
  display: flex; }

.projects .project-summary .segment {
  border-top: 2px solid var(--gray-600); }
  .projects .project-summary .segment .segment-name {
    color: var(--gray-800);
    font-weight: 600; }
  .projects .project-summary .segment .lock {
    width: 18px;
    margin-right: 1.5rem; }

.projects .project-summary .summary-stats {
  width: 12rem; }
  .projects .project-summary .summary-stats .stats-detail-number {
    float: right;
    font-weight: 600; }

.projects .custom-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; }
