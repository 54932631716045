html {
  min-width: var(--min-app-width); }
  html body {
    font-family: var(--fontFamily);
    margin: 0;
    padding: 0; }
    html body > div#root {
      height: 100%; }
      html body > div#root > .panel-portal-app {
        display: grid;
        grid-template-rows: auto 1fr;
        height: 100vh; }
        html body > div#root > .panel-portal-app > .container {
          background-color: var(--app-body-bg-color);
          height: 100%;
          max-width: 100%;
          padding: 0; }
          html body > div#root > .panel-portal-app > .container .welcome-page {
            height: inherit;
            display: flex;
            justify-content: center;
            align-items: center; }
          html body > div#root > .panel-portal-app > .container .app-body {
            display: grid;
            grid-template-columns: 7rem minmax(50rem, 1fr);
            grid-template-rows: 100%;
            height: 100%; }
            html body > div#root > .panel-portal-app > .container .app-body .body-container {
              padding: 0.5rem 0 0 0.5rem;
              height: 100%; }
        html body > div#root > .panel-portal-app .l1-bg {
          background-color: var(--app-body-l1-bg-color); }
        html body > div#root > .panel-portal-app .l2-bg {
          background-color: var(--app-body-l2-bg-color); }
        html body > div#root > .panel-portal-app .l3-bg {
          background-color: var(--app-body-l3-bg-color); }
